import React, {useState} from "react"

type Props = {
  isBgTransparent?: boolean
}

export function Header(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen((isMenuOpen) => !isMenuOpen);
    // find body tag
    const ham = document.querySelector('body');
    if (ham) {
      ham.classList.toggle('active-menu');
    }
  }

  return (
    <header className={`${props.fixedHeader ? 'fixed top-0 left-0 right-0' : ''} ${isMenuOpen ? 'fixed-menu' : ''} text-cloudyBlue container mx-auto font-body absolute inset-x-0 font-normal lg:font-medium w-full z-50`}>
      <div className="mx-auto flex flex-wrap justify-between p-4 md:p-3 flex-col md:flex-row items-center relative">
        <a href="/" id="header_home" className="flex items-center text-b md:mb-0 z-50">
          <img className="object-contain" src={isMenuOpen ? "../mmj-logo-white.png" : "../mmj-vn-logo.png"} width="69" height="46" alt="logo image" />
        </a>
        <button 
          onClick={toggleMenu}
          className={`${isMenuOpen ? 'is-active' : ''} cursor-pointer md:hidden block absolute hamburger--black top-0 left-4 hamburger hamburger--elastic js-hamburger z-50`} 
          type="button"
        >
          <span className="hamburger-box"><span className="hamburger-inner"></span></span>
        </button>
        <nav className={`${isMenuOpen ? 'is-show' : '' } nav-main font-body md:m-auto flex flex-col md:flex-row md:p-0 flex-wrap md:items-center items-start text-2xl md:text-base text-white md:text-black justify-between`} id="menu">
          <div className="flex flex-col md:flex-row md:items-center font-Medium md:font-body items-start w-full links">
            <a href="/products" id="link_features" className="link_features font-Medium md:w-auto w-full md:mr-14 md:py-0 py-4 text-white md:text-black hover:text-tealish">Products</a>
            <a href="/culture" id="link_why-flima" className="link_why-flima font-Medium md:w-auto w-full md:mr-14 md:py-0 py-4 text-white md:text-black hover:text-tealish">Culture</a>
            <a href="/joinOurTeam" id="link_roadmap" className="link_roadmap font-Medium md:w-auto w-full md:mr-14 md:py-0 py-4 text-white md:text-black hover:text-tealish">Join Our Team</a>
            <a href="/#token_distribute" id="link_token-distribution" className="link_token-distribution font-Medium md:w-auto w-full md:mr-14 md:py-0 py-4 text-white md:text-black hover:text-tealish">Blog</a>
          </div>
        </nav>
      </div>
    </header>
  )
}
