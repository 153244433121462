import * as React from "react"
import { Header } from '../components/Header_Black';
import { Footer } from '../components/Footer';
import { Meta } from '../components/Meta';
import { navigate } from "gatsby";

// markup
const ProductsPage = () => {
  return (
    <main className="font-body text-black mx-auto">
      <Meta title="Products Page" bodyClasses="" />
      <Header />
      <section className="product-section bg-clip-text bg-no-repeat " id="productsec">  
          <div className="pt-32 sm:pt-20 items-center container mx-auto">
            <div className="w-1/2 sm:w-full ">
              <h1 className="lg:ml-4">
                <span className="w-1/2 text-beeg lg:text-[60px] font-extrabold text-white font-Bold text-outline-blue tracking-tight ">Products</span><br />
              </h1>
              <div className="lg:ml-4 text-black font-medium text-lg lg:text-mid pt-[18px]">
                <p className=" mb-1.5">👉 We answer a <span className="font-Bold">market demand.</span></p>
                <p className=" mb-1.5">👉 We create a <span className="font-Bold">unique service. </span></p>
                <p className=" mb-8">👉 We improve the software to create more <span className="font-Bold">value for users.</span></p>
              </div>
              <div className="lg:ml-4 sm:mx-4">
                <button id="btn_launch-app" onClick={()=>{navigate("/joinOurTeam")}} className="hover:bg-indigo-800 text-white text-sm font-Medium font-medium mt-8 flex items-center bg-tealish sm+:px-6 py-3 rounded-lg sm:w-full ">
                  <div className="mx-auto">Join Us</div>
                </button> 
              </div>
            </div>
          </div>
          <img className="w-1/2 sm:min-w-full sm:pt-8 max-w-[704px] sm+:absolute sm+:right-0 sm+:top-32" src="../../group.png" />
      </section>
      <section className="ymeetme-section pt-32 sm:pt-24 max-w-7xl mx-auto sm:mx-4" id="ymeetmesec">
        <div className="mx-auto max-w-4xl pb-16 sm:pb-9">
          <div className="pb-12 sm:pb-9">
            <div className="pl-0 font-Bold font-bold text-lg sm:text-mid">ymeet.me</div>
            <div className="pl-0 font-Bold font-bold text-4.5xl sm:text-4xl tracking-[-0.23px]" >
              <span className="text-gradient-blue">No.1 Dating App </span>
              <span>in Vietnam.</span>
            </div>
          </div>
          <div className=" flex flex-row sm:flex-col flex-wrap justify-between">
            <div className="w-9/20 sm:w-full">
              <div className="font-Medium text-black pb-4 text-md sm:text-mid">👨‍💻 Tech stack</div>
              <div className="text-black pb-4 text-sm sm:text-base">• Frontend: React, ReactNative, Redux, Typescript.</div>
              <div className="text-black pb-4 sm:pb-9 text-sm sm:text-base">• Backend: Ruby on Rails, NodeJS, Typescript, MySQL, Cassandra, MongoDB.</div>
            </div>
            <div className="w-9/20 sm:w-full">
              <div className="font-Medium text-black pb-4 text-md sm:text-mid">🛠 Problems to be solved</div>
              <div className="text-black pb-4 text-sm sm:text-base">• Optimize business model to improve revenue of the services. We apply hybrid monetization, changing the business model and targeting the countryside area.</div>
              <div className="text-black pb-4 sm:pb-9 text-sm sm:text-base">• Improve performance and UX.</div>
            </div>
            <div className="w-9/20 sm:w-full">
              <div className="font-Medium text-black pb-4 text-md sm:text-mid">⛺️ Product stage</div>
              <div className="text-black pb-4 text-sm sm:text-base">Improving Business Model.</div>
            </div>
          </div>
        </div>
        <div className="pb-24 text-center">
          <img className="object-contain object-center mx-auto max-w-[814px] lg:max-w-[80%] sm:hidden " src="../../group-11.png" />
          <img className="object-contain object-center sm+:hidden max-w-full " src="../../group-11-mobile.png" />
        </div>
      </section>
      <section className="flima-section bg-paleGrey pt-32 sm:pt-16 " id="flimasec">
        <div className="mx-auto sm:mx-4">
          <div className="mx-auto max-w-4xl pb-16">
            <div className="pb-6 sm:pb-4">
              <div className="pl-0 font-Bold font-bold text-lg sm:text-mid">flima.io</div>
              <div className="pl-0 font-Bold font-bold text-4.5xl sm:text-4xl tracking-[-1.5px]" >
                <span className="text-gradient-blue">NFT + DeFi + Game </span>
                <span>project that earns yield faster and more flexible on the NFT marketplace.</span>
              </div>
            </div>
            <div className="font-Medium text-black text-sm pb-12 sm:pb-9">Flima provides a flexible and safe market to maximize game players’ profit with some features: Lending Protocol, Allow Fractionalized NFT, NFT P2P Market & Auction, Affiliate.</div>
            <div className=" flex flex-row sm:flex-col flex-wrap justify-between">
              <div className="w-9/20 sm:w-full">
                <div className="font-Medium text-black pb-4 text-md sm:text-mid">⛺️ Product stage</div>
                <div className="text-black pb-4 text-sm sm:text-base">Very initial stage of Token Presale and Staking starts.</div>
              </div>
              <div className="w-9/20 sm:w-full">
                <div className="font-Medium text-black pb-4 text-md sm:text-mid">🛠 Problems to be solved</div>
                <div className="text-black pb-4 text-sm sm:text-base">Launch a brand new blockchain product and make it robust, secure, and scalable.</div>
              </div>
            </div>
          </div>
          <div className="pb-24">
            <img className="object-contain object-center mx-auto max-w-[968px] lg:max-w-full sm:hidden" src="../../group-14.png" />
            <img className="object-contain object-center mx-auto sm+:hidden" src="../../group-14-mobile.png" />
          </div>
        </div>
      </section>
      <section className="cokhach-section pt-32 sm:pt-16" id="cokhachsec">
        <div className="mx-auto max-w-4xl pb-16 sm:mx-4">
          <div className="pb-6 sm:pb-4">
            <div className="pl-0 font-Bold font-bold text-lg sm:text-mid">cokhach.vn</div>
            <div className="pl-0 font-Bold font-bold text-4.5xl sm:text-4xl" >
              <span className="text-gradient-blue">All in one marketing solution </span>
              <span>for Real Estate industry.</span>
            </div>
          </div>
          <div className="font-Medium text-black text-sm pb-12 sm:pb-9">cokhach.vn is the No.1 choice of Marketing and Advertising in Real Estate industry. The software provides comprehensive marketing solutions to make sales effective at low cost.</div>
          <div className=" flex flex-row sm:flex-col flex-wrap justify-between">
            <div className="w-9/20 sm:w-full">
              <div className="font-Medium text-black pb-4 text-md sm:text-mid">👨‍💻 Tech stack</div>
              <div className="text-black pb-4 text-sm sm:text-base">• Frontend: React, Typescript.</div>
              <div className="text-black pb-4 text-sm sm:text-base">• Backend: Ruby on Rails, NodeJS, Typescript, PostgreSQL, Apache Kafka, Clickhouse.</div>
            </div>
            <div className="w-9/20 sm:w-full">
              <div className="font-Medium text-black pb-4 text-md sm:text-mid">🛠 Problems to be solved</div>
              <div className="text-black pb-4 text-sm sm:text-base">We are waiting for the right time of Real Estate market in Vietnam to launch out this product.</div>
            </div>
            <div className="w-9/20 sm:w-full">
              <div className="font-Medium text-black pb-4 text-md sm:text-mid">⛺️ Product stage</div>
              <div className="text-black pb-4 text-sm sm:text-base">Complete the 1st development phase.</div>
            </div>
          </div>
        </div>
        <div className="pb-24 sm:mx-12">
          <img className="object-contain object-center sm+:max-w-[568px] mx-auto" src="../../group-15.png" />
        </div>
      </section>
      <Footer />
    </main>
)   
}
export default ProductsPage